import { render, staticRenderFns } from "./Campos-Novos.vue?vue&type=template&id=6f1178fd&scoped=true&"
import script from "./Campos-Novos.vue?vue&type=script&lang=js&"
export * from "./Campos-Novos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f1178fd",
  null
  
)

export default component.exports